import { Container, Divider, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBookingDetail } from "../data/booking.api";
import { AxiosResponse } from "axios";
import { InterfaceBookingDetailData } from "../data/booking.interface";
import { useEffect, useState } from "react";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";
import logo from "../../../assets/img/logo.png";

export default function BookingPrint() {

    const dispatch = useDispatch<any>()
    const { id } = useParams()

    const [bookingDetail, setBookingDetail] = useState<InterfaceBookingDetailData | null>(null)

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getBookingDetail(parseInt(id)))
                .unwrap()
                .then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        const bookingData: InterfaceBookingDetailData = response.data.data
                        setBookingDetail(bookingData)
                    }
                })
        }
    }, [dispatch, id])

    return (
        <Container maxWidth="xl">
            <table style={{ width: "100%", fontWeight: "bold" }}>
                <tbody>
                    {bookingDetail?.customer_list.map((customer) => (
                        <tr key={customer.id}>
                            <td className="ticket">
                                <Divider />
                                <Grid container spacing={1}>
                                    <Grid item xs={3} display={"flex"} alignItems={"center"}>
                                        <img src={logo} alt="logo" height={56} />
                                    </Grid>
                                    <Grid item xs={4} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                        <Typography fontWeight={"bold"}> PT. Bintang Asri Transindo</Typography>
                                        <Typography fontWeight={"bold"}> Jl. H. Supu Yusuf no.28, Kota Kendari</Typography>
                                        <Typography fontWeight={"bold"}> 0811303177/0811353488</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography fontWeight={"bold"}>
                                            Syarat dan ketentuan
                                        </Typography>
                                        <ul style={{ marginTop: 0 }}>
                                            <li>Penumpang diharap berada di perwakilan 30 menit sebelum waktu keberangkatan</li>
                                            <li>penumpang yang membawa barang berharga agar menjaga dengan baik, kehilangan bukan tanggung jawab BA</li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Armada</td>
                                                    <td>:</td>
                                                    <td>{bookingDetail.itinerary?.itinerary.car.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama</td>
                                                    <td>:</td>
                                                    <td>{customer.customer.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Dari</td>
                                                    <td>:</td>
                                                    <td>{bookingDetail.schedule.start_city.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ke</td>
                                                    <td>:</td>
                                                    <td>{bookingDetail.schedule.finish_city.name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Jadwal</td>
                                                    <td>:</td>
                                                    <td>{bookingDetail.schedule.start_time}</td>
                                                </tr>
                                                <tr>
                                                    <td>Harga</td>
                                                    <td>:</td>
                                                    <td>{moneyParser(customer.price)} ({bookingDetail.total_paid < bookingDetail.total ? "Belum Lunas" : "Lunas"})</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontWeight={"bold"} variant="h6" align="right">{moment(bookingDetail.start_date).format('dddd, DD MMMM YYYY')}{` `}</Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">
                                                    SUPER
                                                </Typography>
                                                <Typography variant="h4">
                                                    LUXURY
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography fontWeight={"bold"} variant="h1" align="center">{customer.seat.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    )
}