import { Box, Card, CardContent, Divider, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../../account/data/account.api";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import { createReportStructureBalance, getReport, getReportStructureBalance, removeReportStructureBalance } from "../data/report.api";
import { InterfaceAccountData } from "../../account/data/account.interface";
import { Add, Remove } from "@mui/icons-material";

export default function Balance(props: { isEditActive: boolean, isEditPasive: boolean }) {

    const dispatch = useDispatch<any>();

    const { accountList } = useSelector((state: RootState) => state.account)
    const { reportList, reportStructureBalance } = useSelector((state: RootState) => state.report)

    useEffect(() => {
        dispatch(getAccount())
        dispatch(getReport())
        dispatch(getReportStructureBalance())
    }, [dispatch])

    const handleStructureAdd = async (accountId: number) => {
        await dispatch(createReportStructureBalance({
            account_id: accountId,
            is_active: props.isEditActive,
            is_pasiva: props.isEditPasive
        }))
        await dispatch(getReportStructureBalance())
    }

    const handleStructureRemove = async (accountId: number) => {
        await dispatch(removeReportStructureBalance(accountId))
        await dispatch(getReportStructureBalance())
    }

    const reportStructureIdList = reportStructureBalance.map(report => report.account_id)
    const reportStructureIdActiveList = reportStructureBalance.filter(report => report.is_active).map(report => report.account_id)
    const reportStructureIdPasiveList = reportStructureBalance.filter(report => report.is_pasiva).map(report => report.account_id)

    console.log(reportStructureBalance, reportStructureIdActiveList, reportStructureIdPasiveList)

    const renderItem = (list: InterfaceAccountData[], parrent: null | number) => {
        return list.filter(account => account.parrent_id === parrent).map(account => {
            let totalCredit = 0
            let totalDebet = 0
            if (account.is_head) {
                totalCredit = reportList.filter(report => getAllChildIds([account.id]).includes(report.account_source.parrent_id)).reduce((total, r) => total + r.value, 0)
                totalDebet = reportList.filter(report => getAllChildIds([account.id]).includes(report.account.parrent_id)).reduce((total, r) => total + r.value, 0)
            }
            else {
                totalCredit = reportList.filter(report => report.account_source.id === account.id).reduce((total, r) => total + r.value, 0)
                totalDebet = reportList.filter(report => report.account.id === account.id).reduce((total, r) => total + r.value, 0)
            }
            return (
                <Box
                    key={account.id}
                    marginLeft={3}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        padding={1}
                        sx={{
                            ":hover": {
                                bgcolor: "primary.main"
                            }
                        }}
                    >
                        <Typography>[{account.code}] {account.name}</Typography>
                        {(props.isEditActive || props.isEditPasive)
                            ? (
                                account.is_head && (
                                    reportStructureIdList.includes(account.id)
                                        ? (
                                            <IconButton onClick={() => handleStructureRemove(account.id)}><Remove /></IconButton>
                                        )
                                        : (
                                            <IconButton onClick={() => handleStructureAdd(account.id)}><Add /></IconButton>
                                        )
                                )
                            )
                            : (
                                <Typography fontWeight={account.is_head ? "bold" : undefined}>{moneyParser(account.is_negate ? totalCredit - totalDebet : totalDebet - totalCredit)}</Typography>
                            )
                        }
                    </Box>
                    {renderItem(list, account.id)}
                </Box>
            )
        })
    }

    const getAllChildIds = (parentIds: number[]) => {
        let childIds: number[] = parentIds;

        const findChildren = (parentIds: number[]) => {
            accountList.forEach(item => {
                if (item.parrent_id !== null && parentIds.includes(item.parrent_id)) {
                    childIds.push(item.id);
                    findChildren([item.id]); // Recursively find children of this item
                }
            });
        }

        findChildren(parentIds);
        return childIds;
    }


    const totalActiveCredit = reportList.filter(report => getAllChildIds(reportStructureIdActiveList).includes(report.account_source.parrent_id)).reduce((total, r) => total + r.value, 0)
    const totalActiveDebet = reportList.filter(report => getAllChildIds(reportStructureIdActiveList).includes(report.account.parrent_id)).reduce((total, r) => total + r.value, 0)
    const totalPasivaCredit = reportList.filter(report => getAllChildIds(reportStructureIdPasiveList).includes(report.account_source.parrent_id)).reduce((total, r) => total + r.value, 0)
    const totalPasivaDebet = reportList.filter(report => getAllChildIds(reportStructureIdPasiveList).includes(report.account.parrent_id)).reduce((total, r) => total + r.value, 0)
    


    return (
        <Card>
            <CardContent>
                <Typography variant="h4">Activa</Typography>
                {renderItem(
                    props.isEditActive ? accountList : accountList.filter(account => getAllChildIds(reportStructureIdActiveList).includes(account.id)),
                    null
                )}
                <Typography variant="h4">Pasiva</Typography>
                {renderItem(
                    props.isEditPasive ? accountList : accountList.filter(account => getAllChildIds(reportStructureIdPasiveList).includes(account.id)),
                    null
                )}
                <Divider sx={{ marginY: 2 }} />
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                >
                    <Typography variant="h4">Total Aktiva</Typography>
                    <Typography variant="h4">{moneyParser(totalActiveDebet - totalActiveCredit)}</Typography>
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                >
                    <Typography variant="h4">Total Pasiva</Typography>
                    <Typography variant="h4">{moneyParser(totalPasivaDebet - totalPasivaCredit)}</Typography>
                </Box>
            </CardContent>
        </Card>
    )
}