import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { InterfaceItineraryAttachmentUpload, InterfaceItineraryClose, InterfaceItineraryCostAdd, InterfaceItineraryCostEdit, InterfaceItineraryCreate, InterfaceItineraryEdit } from "./itinerary.interface";
import { RootState } from "../../../store";

export const getItinerary = createAsyncThunk('getItinerary', async (params: { skip?: number, take?: number }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary',
            params: params
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getItineraryCalendar = createAsyncThunk('getItineraryCalendar', async (params: { year?: number, month?: number }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/calendar',
            params: params
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getItineraryByScheduleAndStartDate = createAsyncThunk('getItineraryByScheduleAndStartDate', async (params: { schedule_id: number, start_date: string }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/byscheduleandstartdate',
            params: params
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getItineraryOpen = createAsyncThunk('getItineraryOpen', async (params: { skip?: number, take?: number }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/open',
            params: params
        })
        return response
    }
    catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getItineraryDetail = createAsyncThunk('getItineraryDetail', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/' + id,
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createItinerary = createAsyncThunk('createItinerary', async (data: InterfaceItineraryCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const editItinerary = createAsyncThunk('editItinerary', async (data: InterfaceItineraryEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteItinerary = createAsyncThunk('deleteItinerary', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})


export const editItineraryCost = createAsyncThunk('editItineraryCost', async (data: InterfaceItineraryCostEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/cost',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createItineraryCost = createAsyncThunk('createItineraryCost', async (data: InterfaceItineraryCostAdd, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/cost',
            data: data
        })
        return response
    }
    catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteItineraryCost = createAsyncThunk('deleteItineraryCost', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/cost',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const closeItinerary = createAsyncThunk('closeItinerary', async (data: InterfaceItineraryClose, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/close',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const appendBookingItinerary = createAsyncThunk('appendBookingItinerary', async (data: { itinerary_id: number, booking_id: number }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/append/booking',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const appendDeliveryItinerary = createAsyncThunk('appendDeliveryItinerary', async (data: { itinerary_id: number, delivery_id: number }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/itinerary/append/delivery',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const attachmentUpload = createAsyncThunk('attachmentUpload', async (data: InterfaceItineraryAttachmentUpload, thunkAPI) => {
    try {
        const response = await axios.post(baseUrl + '/itinerary/attachment', data, {
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key,
                'Content-Type': 'multipart/form-data'
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const attachmentGet = createAsyncThunk('attachmentGet', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key,
            },
            url: baseUrl + '/itinerary/attachment/' + id
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getItineraryReport = createAsyncThunk('getItineraryReport', async (params: { date_start: string, date_end: string }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key,
            },
            url: baseUrl + '/itinerary/report',
            params: params
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})