import { InterfaceCityData } from "../../city/data/city.interface"
import { InterfaceCustomerData } from "../../customer/data/customer.interface"
import { InterfaceScheduleData } from "../../schedule/data/schedule.interface"
import { InterfaceSeatData } from "../../seat/data/seat.interface"
import { InterfaceTerminalData } from "../../terminal/data/terminal.interface"

export interface InterfaceBookingCustomerListData {
    customer: InterfaceCustomerData,
    seat: {
        id: number,
        name: string,
        schedule_id: number
    }
}

export interface InterfaceBookingData {
    id: number,
    ts_created: string,
    schedule_id: number,
    start_date: string,
    start_terminal_id: number,
    finish_terminal_id: number,
    schedule: {
        id: number,
        start_time: string,
        start_city: {
            id: number,
            name: string
        },
        finish_city: {
            id: number,
            name: string
        }
    },
    total_customer: number,
    total: number,
    total_paid: number
}

export interface InterfaceBookingDetailData extends InterfaceBookingData {
    customer_list: {
        id: number,
        ts_created: string,
        price: number,
        discount: number,
        customer: {
            name: string
        },
        seat: {
            name: true
        }
        is_ready: boolean,
    }[]
    paid_list: {
        id: number,
        ts_created: string,
        name: string,
        account_id: number,
        value: number,
        account: {
            name: string
        }
    }[]
    start_terminal?: {
        name: string
    },
    finish_terminal?: {
        name: string
    },
    itinerary: {
        itinerary: {
            car: {
                name: string
            }
        }
    }
}

export interface InterfaceBookingInputCustomerList {
    customer: InterfaceCustomerData,
    discount?: number,
    price?: number,
    seat?: InterfaceSeatData
}

export interface InterfaceBookingCreatePaid {
    name: string,
    account_source_id: number,
    account_id: number,
    value: number
}

export interface InterfaceBookingCreate {
    ts_created: string,
    schedule_id: number,
    start_date: string,
    start_terminal_id?: number,
    finish_terminal_id?: number,
    customer_list: {
        customer_id: number,
        seat_id: number,
        price: number,
        discount: number
    }[]
    paid_list: InterfaceBookingCreatePaid[]
}

export interface InterfaceBookingEdit {
    id: number,
    start_terminal_id?: number,
    finish_terminal_id?: number
}

export interface InterfaceBookingInput {
    ts_created?: string,
    start_date?: string,
    start_city?: InterfaceCityData,
    start_terminal?: InterfaceTerminalData,
    finish_city?: InterfaceCityData,
    finish_terminal?: InterfaceTerminalData,
    schedule?: InterfaceScheduleData,
    customer_list?: InterfaceBookingInputCustomerList[]
}

export interface InterfaceBookingPaidCreate extends InterfaceBookingCreatePaid {
    booking_id: number
}

export interface InterfaceBookingForItinerary {
    id: number,
    start_date: string,
    start_terminal?: {
        name: string
    },
    finish_terminal?: {
        name: string
    },
    customer_list: {
        price: number,
        discount: number,
        customer: {
            name: string
        },
        seat: {
            name: string
        }
    }[]
}


export const InitialBookingCreatePaid: InterfaceBookingCreatePaid = {
    name: '',
    account_source_id: 0,
    account_id: 0,
    value: 0
}