import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Card, CardContent, Checkbox, Grid, IconButton, List, ListItemButton, ListItemSecondaryAction, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBooking } from "../../data/booking.api";
import { RootState } from "../../../../store";
import { InterfaceBookingData } from "../../data/booking.interface";
import CustomModal from "../../../../shared/CustomModal";
import CalendarDatePreview from "./CalendarDatePreview";
import Input from "../input/Input";
import { setBookingInput } from "../../data/booking.reducer";
import { getSchedule } from "../../../schedule/data/schedule.api";
import useAccess from "../../../../hooks/useAccess";
import { resetItineraryInput } from "../../../itinerary/data/itinerary.reducer";
import { getItineraryCalendar } from "../../../itinerary/data/itinerary.api";
import { green } from "@mui/material/colors";

export default function Calendar() {

    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
    const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"]

    const dispatch = useDispatch<any>()
    const access = useAccess("/booking")

    const { bookingList } = useSelector((state: RootState) => state.booking)
    const { scheduleList } = useSelector((state: RootState) => state.schedule)
    const { itineraryCalendarList } = useSelector((state: RootState) => state.itinerary)

    const [year, setYear] = useState(moment().year())
    const [month, setMonth] = useState(moment().month())
    const [cityStartId, setCityStartId] = useState<number>(0)
    const [cityFinishId, setCityFinishId] = useState<number>(0)
    const [dateSelected, setDateSelected] = useState<string>("")
    const [bookingSelectedList, setBookingSelectedList] = useState<InterfaceBookingData[]>([])
    const [bookingIdSelected, setBookingIdSelected] = useState<number | undefined>(undefined)

    useEffect(() => {
        dispatch(getSchedule({}))
        dispatch(setBookingInput({}))
        dispatch(resetItineraryInput())
    }, [dispatch])

    useEffect(() => {
        const scheduleListShow = scheduleList.filter(schedule => schedule.is_show_on_calendar)
        if (scheduleListShow.length > 0 && cityStartId === 0 && cityFinishId === 0) {
            setCityStartId(scheduleListShow[0].start_city_id)
            setCityFinishId(scheduleListShow[0].finish_city_id)
            setBookingIdSelected(scheduleListShow[0].id)
        }
    }, [scheduleList, cityStartId, cityFinishId])

    useEffect(() => {
        if (cityStartId !== 0 && cityFinishId !== 0) {
            dispatch(getBooking({
                year: year,
                month: month + 1,
                city_start_id: cityStartId,
                city_finish_id: cityFinishId
            }))
            dispatch(getItineraryCalendar({
                year: year,
                month: month + 1
            }))
        }
    }, [dispatch, year, month, cityStartId, cityFinishId])

    let weekList: Moment[][] = []
    const firstDayOfMonth = moment({ year, month: month, day: 1 })
    const firstWeekNumber = firstDayOfMonth.isoWeek();
    for (let i = firstWeekNumber; i <= firstWeekNumber + 5; i++) {
        const weekNumber = i
        const startDate = moment().year(year).week(weekNumber).startOf('week')
        let dateList = []
        for (let j = 0; j < 7; j++) {
            dateList.push(startDate.clone().add(j, "day"))
        }
        weekList.push(dateList)
    }

    const handleNext = () => {
        if (month < 11) {
            setMonth(month + 1)
        }
        else {
            setYear(year + 1)
            setMonth(0)
        }
    }

    const handlePrev = () => {
        if (month > 0) {
            setMonth(month - 1)
        }
        else {
            setYear(year - 1)
            setMonth(11)
        }
    }

    const handleDateClick = (date: string, bookedInDate: InterfaceBookingData[]) => {
        setDateSelected(date)
        setBookingSelectedList(bookedInDate)
    }

    const handleCloseInput = () => {
        setDateSelected("")
        setBookingSelectedList([])
        dispatch(setBookingInput({}))
    }

    const handleSubmitInput = () => {
        setDateSelected("")
        setBookingSelectedList([])
        dispatch(setBookingInput({}))
        getData()
    }

    const getData = () => {
        dispatch(getBooking({
            year: year,
            month: month + 1,
            city_start_id: cityStartId,
            city_finish_id: cityFinishId
        }))
    }

    return (
        <Box>
            <CustomModal
                open={bookingSelectedList.length > 0}
                onClose={handleCloseInput}
                title={"Detail Booking"}
                component={<CalendarDatePreview bookingList={bookingSelectedList} onChange={getData} />}
            />
            <CustomModal
                open={access("CREATE") && dateSelected !== "" && bookingSelectedList.length === 0}
                onClose={handleCloseInput}
                title={"Input Booking"}
                size="lg"
                component={<Input startDate={dateSelected} startCityId={cityStartId} finishCityId={cityFinishId} scheduleId={bookingIdSelected} isAllowSubmit={true} onSubmit={handleSubmitInput} />}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <List>
                                {scheduleList.filter(schedule => schedule.is_show_on_calendar).map(schedule => (
                                    <ListItemButton key={schedule.id} onClick={() => { setCityStartId(schedule.start_city_id); setCityFinishId(schedule.finish_city_id); setBookingIdSelected(schedule.id) }}>
                                        <Typography variant="h6">{schedule.start_city.name} - {schedule.finish_city.name} </Typography>
                                        <ListItemSecondaryAction>
                                            <Checkbox checked={schedule.start_city_id === cityStartId && schedule.finish_city_id === cityFinishId} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{ marginTop: 1 }}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography variant="h6">{monthNames[month]}</Typography>
                            <Typography>{year}</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={handlePrev}><KeyboardArrowLeft /></IconButton>
                            <IconButton onClick={handleNext}><KeyboardArrowRight /></IconButton>
                        </Box>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {dayNames.map((day, i) => (
                                    <TableCell key={i}>{day}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {weekList.map((dateList, i) => (
                                <TableRow key={i}>
                                    {dateList.map((date, j) => {
                                        const bookedInDate = bookingList.filter((booked) => moment(booked.start_date).format("YYYY-MM-DD") === date.format("YYYY-MM-DD"))
                                        const scheduleList = bookedInDate.map(booked => booked.schedule).filter((value, index, self) => self.findIndex(s => s.id === value.id) === index)
                                        const isItineraryClosed = itineraryCalendarList.filter(itinerary => itinerary.is_close && moment(itinerary.start_date).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")).length > 0
                                        return (
                                            <TableCell key={j} sx={{ verticalAlign: "top", opacity: date.month() === month ? 1 : 0.2 }} onClick={() => handleDateClick(date.format("YYYY-MM-DD"), bookedInDate)}>
                                                <Box border={1} padding={1} borderRadius={1} sx={{ cursor: "pointer", bgcolor: isItineraryClosed ? green[100] : undefined }}>
                                                    <Typography align="right" fontWeight="bold" sx={{ fontSize: "1.2rem" }}>{date.date()}</Typography>
                                                    {scheduleList.map((schedule, k) => (
                                                        <Box key={k} borderBottom={1} marginTop={1} >
                                                            <Typography fontWeight="bold" > {schedule.start_city.name} - {schedule.finish_city.name}</Typography>
                                                            <Typography fontWeight="bold" >{bookedInDate.filter(booked => booked.schedule_id === schedule.id).reduce((total, booked) => total + booked.total_customer, 0)} Penumpang</Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    )
}