import { InterfaceCityData } from "../../city/data/city.interface"
import { InterfacePackageTypeData } from "../../packageType/data/packageType.interface"
import { InterfaceScheduleData } from "../../schedule/data/schedule.interface"
import { InterfaceTerminalData } from "../../terminal/data/terminal.interface"

export interface InterfaceDeliveryData {
    id: number,
    ts_created: string,
    schedule_id: number,
    start_date: string,
    package_type_id: number,
    start_terminal_id: number,
    finish_terminal_id: number,
    sender_name: string,
    sender_phone: string,
    sender_address: string,
    receiver_name: string,
    receiver_phone: string,
    receiver_address: string,
    notes: string,
    price: number,
    price_packing: number,
    discount: number,
    schedule: {
        id: number,
        start_city: {
            name: string
        },
        finish_city: {
            name: string
        }
    },
    package_list: {
        weight: number
    }[],
    discount_price: number,
    total: number,
    total_paid: number
}

export interface InterfaceDeliveryDetail extends InterfaceDeliveryData {
    paid_list: {
        id: number,
        ts_created: string,
        name: string,
        account_id: number,
        value: number,
        account: {
            name: string
        }
    }[],
    package_list: {
        id: number,
        ts_created: string,
        name: string,
        weight: number,
    }[],
    start_terminal?: {
        name: string
    },
    finish_terminal?: {
        name: string
    },
    package_type: {
        name: string
    }
}

export interface InterfaceDeliveryCreatePaid {
    name: string,
    account_source_id: number,
    account_id: number,
    value: number
}

export interface InterfaceDeliveryCreatePackage {
    name: string,
    weight: number,
}

export interface InterfaceDeliveryCreate {
    ts_created: string,
    schedule_id: number,
    start_date: string,
    package_type_id: number,
    start_terminal_id?: number,
    finish_terminal_id?: number,
    sender_name: string,
    sender_phone: string,
    sender_address: string,
    receiver_name: string,
    receiver_phone: string,
    receiver_address: string,
    notes: string,
    price: number,
    price_packing: number,
    discount: number,
    paid_list: InterfaceDeliveryCreatePaid[],
    package_list: InterfaceDeliveryCreatePackage[]
}

export interface InterfaceDeliveryEdit {
}

export interface InterfaceDeliveryInput {
    ts_created?: string,
    schedule?: InterfaceScheduleData,
    start_date?: string,
    package_type?: InterfacePackageTypeData
    start_city?: InterfaceCityData,
    start_terminal?: InterfaceTerminalData,
    finish_city?: InterfaceCityData,
    finish_terminal?: InterfaceTerminalData,
    sender_name?: string,
    sender_phone?: string,
    sender_address?: string,
    receiver_name?: string,
    receiver_phone?: string,
    receiver_address?: string,
    notes?: string,
    price_packing?: number,
    discount?: number,
    package_list?: InterfaceDeliveryCreatePackage[]
}

export const InitialDeliveryCreatePaid: InterfaceDeliveryCreatePaid = {
    name: '',
    account_source_id: 0,
    account_id: 0,
    value: 0
}