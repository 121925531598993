import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getItineraryDetail } from "../data/itinerary.api"
import { RootState } from "../../../store"
import { Box, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import SeatPrint from "../../seat/components/SeatPrint"
import moneyParser from "../../../shared/moneyParser"
import useAccess from "../../../hooks/useAccess"

export default function ItineraryPrint() {

    const { id } = useParams()
    const dispatch = useDispatch<any>()
    const access = useAccess("/itinerary")

    const { itineraryDetail } = useSelector((state: RootState) => state.itinerary)

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getItineraryDetail(parseInt(id)))
        }
    }, [dispatch, id])

    if (itineraryDetail === undefined) {
        return <>Loading</>
    }

    const totalTicket = itineraryDetail.booking_list.reduce((totalBooking, booking) => totalBooking + (booking.booking.customer_list.reduce((totalCustomer, customer) => totalCustomer + customer.price - (customer.price * customer.discount / 100), 0)), 0)
    const totalDelivery = itineraryDetail.delivery_list.reduce((totalDelivery, delivery) => {
        const totalPrice = (delivery.delivery.price * delivery.delivery.package_list.reduce((t, p) => t + p.weight, 0)) + delivery.delivery.price_packing
        return totalDelivery + (totalPrice - (totalPrice * delivery.delivery.discount / 100))
    }, 0)
    const totalCost = itineraryDetail.cost_list.reduce((totalCost, cost) => totalCost + cost.value, 0)
    const totalCommision = itineraryDetail.commision_list.reduce((totalCommision, commision) => totalCommision + commision.value, 0)

    return (
        <Box maxHeight={"100vh"}>
            <Card variant="outlined">
                <CardHeader title={`Nomor Itinerary: ${itineraryDetail.id}`} />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography>Driver Utama: {itineraryDetail.driver_primary.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Driver Cadangan: {itineraryDetail.driver_secondary.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Kernet 1: {itineraryDetail.helper_a.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Kernet 2: {itineraryDetail.helper_b.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Armada: {itineraryDetail.car.name}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader title={"Keuangan"} />
                <CardContent>
                    <Typography variant="h6">Pendapatan</Typography>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Tiket</TableCell>
                                <TableCell align="right">{moneyParser(totalTicket)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pengiriman</TableCell>
                                <TableCell align="right">{moneyParser(totalDelivery)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                                <TableCell style={{ fontWeight: "bold" }} align="right">{moneyParser(totalTicket + totalDelivery)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography variant="h6" marginTop={2}>Pengeluaran</Typography>
                    <Table>
                        <TableBody>
                            {itineraryDetail.cost_list.map(cost => (
                                <TableRow key={cost.account_source_id}>
                                    <TableCell>{cost.name}</TableCell>
                                    <TableCell align="right">{moneyParser(cost.value)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                                <TableCell style={{ fontWeight: "bold" }} align="right">{moneyParser(totalCost)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {access("VIEW_SENSITIVE") && (
                        <Fragment>
                            <Typography variant="h6" marginTop={2}>Komisi</Typography>
                            <Table>
                                <TableBody>
                                    {itineraryDetail.commision_list.map(commision => (
                                        <TableRow key={commision.employee_id}>
                                            <TableCell>{commision.employee.name}</TableCell>
                                            <TableCell align="right">{moneyParser(commision.value)}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }} align="right">{moneyParser(totalCommision)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography variant="h6" marginTop={2}>Omset</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Omset Bruto</TableCell>
                                        <TableCell align="right">{moneyParser(totalTicket + totalDelivery - totalCost)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold" }}>Omset Netto</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }} align="right">{moneyParser(totalTicket + totalDelivery - totalCost - totalCommision)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Fragment>
                    )}
                </CardContent>
            </Card>
            <Box sx={{ pageBreakBefore: "always" }}>
                <SeatPrint scheduleId={itineraryDetail.schedule_id} startDate={itineraryDetail.start_date} />
            </Box>
            <Box sx={{ pageBreakBefore: "always" }}>
                <Card>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nomor Itinerary</TableCell>
                                    <TableCell>Jumlah Koli</TableCell>
                                    <TableCell>Total Harga</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itineraryDetail.delivery_list.map((delivery) => {
                                    const totalPrice = (delivery.delivery.price * delivery.delivery.package_list.reduce((t, p) => t + p.weight, 0)) + delivery.delivery.price_packing
                                    return (
                                        <TableRow>
                                            <TableCell>{delivery.delivery.id}</TableCell>
                                            <TableCell>{delivery.delivery.package_list.length}</TableCell>
                                            <TableCell>{moneyParser(totalPrice)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}