import { Box, Button } from "@mui/material";
import { FormEvent, useEffect } from "react";
import { InitialAccountForm, InterfaceAccountForm } from "../data/account.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createAccount, editAccount, getAccount } from "../data/account.api";
import AccountSelect from "./AccountSelect";
import FormMoney from "../../../shared/form/FormMoney";
import FormCheck from "../../../shared/form/FormCheck";

export default function AccountForm(props: { mode: "ADD" | "EDIT", parrentId?: number, data?: InterfaceAccountForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceAccountForm>(props.data || InitialAccountForm)

    useEffect(() => {
        if (props.parrentId) {
            handleChangeValues("parrent_id", props.parrentId)
        }
    }, [props.parrentId, handleChangeValues])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createAccount(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editAccount({
                    id: values.id,
                    code: values.code,
                    name: values.name,
                    parrent_id: values.parrent_id,
                    initial_balance: values.initial_balance,
                    is_negate: values.is_negate
                })).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getAccount())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <AccountSelect value={values.parrent_id} onChange={(value) => handleChangeValues("parrent_id", value)} />
            <FormText label="Kode" name="code" value={values.code} onChange={handleChange} />
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            {(values.id && !values.is_head) && (
                <FormMoney label="Saldo Awal" name="initial_balance" value={values.initial_balance} onChange={handleChange} />
            )}
            <FormCheck label="Nilai Negatif" name="is_negate" value={values.is_negate} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}