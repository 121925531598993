import { Box, Button, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { FormEvent, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../../schedule/data/schedule.api";
import AccountSelect from "../../../account/components/AccountSelect";
import { useSetting } from "../../../../hooks/useSetting";
import FormCheck from "../../../../shared/form/FormCheck";
import { InterfaceDeliveryCreatePaid, InitialDeliveryCreatePaid, InterfaceDeliveryCreate } from "../../data/delivery.interface";
import FormMoney from "../../../../shared/form/FormMoney";
import moneyParser from "../../../../shared/moneyParser";
import { RootState } from "../../../../store";
import { createDelivery } from "../../data/delivery.api";
import alertSuccess from "../../../../shared/alert/alertSuccess";
import FormNumber from "../../../../shared/form/FormNumber";
import { setDeliveryInput } from "../../data/delivery.reducer";

export default function InputE(props: { onSubmit?: (id: number) => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const { deliveryInput } = useSelector((state: RootState) => state.delivery)

    const [isCredit, setIsCredit] = useState(false)
    const [paidList, setPaidList] = useState<InterfaceDeliveryCreatePaid[]>([])
    const [tempDiscountValue, setTempDiscountValue] = useState(0)

    useEffect(() => {
        dispatch(getSchedule({}))
    }, [dispatch])

    useEffect(() => {
        const accountDelivery = setting.getValue("ACCOUNT_DELIVERY")
        if (accountDelivery && paidList.length === 0) {
            const value: InterfaceDeliveryCreatePaid = {
                name: "Pembayaran",
                account_source_id: accountDelivery,
                account_id: 0,
                value: 0
            }
            setPaidList([value])
        }
    }, [paidList, setting])

    const price: number = deliveryInput.package_type?.price || setting.getValue("PRICE_DELIVERY")
    const totalWeight: number = deliveryInput.package_list?.reduce((acc, curr) => acc + curr.weight, 0) || 0
    const priceDelivery: number = totalWeight * price
    const pricePacking: number = deliveryInput.price_packing || 0
    const priceBeforeDiscount: number = priceDelivery + pricePacking
    const priceDiscount: number = priceBeforeDiscount * (deliveryInput.discount || 0) / 100
    const priceTotal: number = priceBeforeDiscount - priceDiscount

    useEffect(() => {
        if (tempDiscountValue !== priceDiscount) {
            setTempDiscountValue(priceDiscount)
        }
    }, [priceDiscount])

    useEffect(() => {
        dispatch(setDeliveryInput({ ...deliveryInput, discount: tempDiscountValue / priceBeforeDiscount * 100 }))
    }, [tempDiscountValue])

    useEffect(() => {
        if (paidList.length > 0) { // pastikan paid_list tidak kosong
            if (isCredit) { // jika kredit
                if (paidList[0].name !== "Uang Muka") {
                    setPaidList(
                        [
                            ...paidList.map((paid, i) => i === 0 ? { ...paid, name: "Uang Muka", value: 0 } : paid),  // ubah nama uang muka
                            {                                                                                       // tambah akun hutang
                                ...InitialDeliveryCreatePaid,
                                name: "Hutang",
                                account_source_id: setting.getValue("ACCOUNT_DELIVERY"),
                                account_id: setting.getValue("ACCOUNT_LOAN"),
                                value: 0
                            }
                        ]
                    )
                }
                else if (priceTotal !== paidList[0].value + paidList[1].value) { // jika total tidak sama dengan uang muka + hutang
                    setPaidList(
                        paidList.map((paid, i) => i === 1 ? { ...paid, value: priceTotal - paidList[0].value } : paid), // ubah nilai hutang
                    )
                }
            }
            else { // jika tunai
                if (priceTotal !== paidList[0]?.value) { // jika total tidak sama dengan pembayaran
                    setPaidList(
                        paidList
                            .map((paid, i) => i === 0 ? { ...paid, name: "Pembayaran", value: priceTotal } : paid) // ubah nama pembayaran dan nilai
                            .filter((_, i) => i === 0) // hapus akun hutang jika ada
                    )
                }
                else {
                    setPaidList(
                        paidList
                            .map((paid, i) => i === 0 ? { ...paid, name: "Pembayaran" } : paid) // ubah nama pembayaran saja
                            .filter((_, i) => i === 0) // hapus akun hutang jika ada
                    )
                }
            }
        }
    }, [priceTotal, isCredit, setting, paidList, deliveryInput])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (
            deliveryInput.ts_created &&
            deliveryInput.start_date &&
            deliveryInput.schedule &&
            deliveryInput.package_type &&
            deliveryInput.package_list
        ) {
            const data: InterfaceDeliveryCreate = {
                ts_created: deliveryInput.ts_created,
                schedule_id: deliveryInput.schedule.id,
                start_date: deliveryInput.start_date,
                package_type_id: deliveryInput.package_type.id,
                start_terminal_id: deliveryInput.start_terminal?.id,
                finish_terminal_id: deliveryInput.finish_terminal?.id,
                sender_name: deliveryInput.sender_name || "",
                sender_phone: deliveryInput.sender_phone || "",
                sender_address: deliveryInput.sender_address || "",
                receiver_name: deliveryInput.receiver_name || "",
                receiver_phone: deliveryInput.receiver_phone || "",
                receiver_address: deliveryInput.receiver_address || "",
                notes: deliveryInput.notes || "",
                price: price,
                price_packing: pricePacking,
                discount: deliveryInput.discount || 0,
                package_list: deliveryInput.package_list,
                paid_list: paidList.filter(paid => paid.account_id !== 0)
            }
            try {
                await dispatch(createDelivery(data))
                    .unwrap()
                    .then(async (response: any) => {
                        await alertSuccess("Berhasil menyimpan data")
                        const dataResponse = response.data.data
                        const id = dataResponse.id
                        dispatch(setDeliveryInput({}))
                        window.open(`/#/delivery/print/${id}`, "_blank")
                        props.onSubmit && props.onSubmit(id)
                    })
            } catch (error) { }
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Harga Per KG</TableCell>
                                <TableCell>{moneyParser(price)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Berat</TableCell>
                                <TableCell>{totalWeight} Kg</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Biaya Pengiriman</TableCell>
                                <TableCell align="right">{moneyParser(priceDelivery)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Biaya Packing</TableCell>
                                <TableCell align="right">{moneyParser(pricePacking)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Biaya Total</TableCell>
                                <TableCell align="right">{moneyParser(priceBeforeDiscount)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Diskon Persen</TableCell>
                                <TableCell align="right">
                                    <FormNumber label="Diskon" value={deliveryInput.discount || 0} onChange={e => dispatch(setDeliveryInput({ ...deliveryInput, discount: parseInt(e.target.value) }))} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Diskon Rupiah</TableCell>
                                <TableCell align="right">
                                    <FormMoney value={tempDiscountValue} onChange={e => setTempDiscountValue(parseInt(e.target.value))} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>Biaya Akhir</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(priceTotal)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} marginTop={3}></Grid>
                <Grid item xs={6}>
                    <AccountSelect label="Akun Sumber" value={setting.getValue("ACCOUNT_DELIVERY")} readonly />
                </Grid>
                <Grid item xs={6}>
                    <FormMoney value={priceTotal} />
                </Grid>
                <Grid item xs={12}>
                    <FormCheck label={"Kredit"} name="is_credit" value={isCredit} onChange={(e) => setIsCredit(e.target.value as unknown as boolean)} />
                </Grid>
                {paidList.length >= 1 && (
                    <Fragment>
                        <Grid item xs={6}>
                            <AccountSelect required={!isCredit} disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label={paidList[0].name} value={paidList[0].account_id} onChange={(value) => setPaidList(paidList.map((paid, i) => i === 0 ? { ...paid, account_id: value || 0 } : paid))} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormMoney value={paidList[0].value} onChange={(e) => setPaidList(paidList.map((paid, i) => i === 0 ? { ...paid, value: parseInt(e.target.value) } : paid))} disabled={!isCredit} />
                        </Grid>
                    </Fragment>
                )}
                {paidList.length >= 2 && (
                    <Fragment>
                        <Grid item xs={6}>
                            <AccountSelect required disabledHead label={paidList[1].name} value={paidList[1].account_id} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormMoney value={paidList[1].value} onChange={(e) => setPaidList(paidList.map((paid, i) => i === 0 ? { ...paid, value: parseInt(e.target.value) } : paid))} disabled />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
            <Button fullWidth sx={{ marginTop: 1 }} type="submit">Submit</Button>
        </Box>
    )
}