import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Balance from "../../features/report/components/Balance";

export default function BalancePage() {

    const toPrint = useRef(null)

    const [structureEditActive, setStructureEditActive] = useState(false)
    const [structureEditPasive, setStructureEditPasive] = useState(false)

    return (
        <Container>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            {(!structureEditActive && !structureEditPasive)
                                ? (

                                    <Fragment>
                                        <Button variant="outlined" fullWidth onClick={() => setStructureEditActive(true)}>Edit Struktur Aktiva</Button>
                                        <Button variant="outlined" fullWidth onClick={() => setStructureEditPasive(true)}>Edit Struktur Pasiva</Button>
                                    </Fragment>
                                )
                                : (
                                    <Button variant="outlined" fullWidth onClick={() => { setStructureEditActive(false); setStructureEditPasive(false) }}>Selesai</Button>
                                )
                            }
                        </Grid>
                        <Grid item xs={8}>
                            <ReactToPrint
                                trigger={() => {
                                    return <Button variant="contained" fullWidth>Print</Button>;
                                }}
                                content={() => toPrint.current}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Box ref={toPrint} padding={2}>
                <Typography variant="h3">Neraca</Typography>
                <Divider sx={{ marginY: 3 }} />
                <Balance isEditActive={structureEditActive} isEditPasive={structureEditPasive} />
            </Box>
        </Container>
    )
}