import { Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { FormEvent, Fragment, useEffect, useState } from "react";
import { InitialBuyForm, InitialBuyPaidForm, InitialBuySparepartForm, InterfaceBuyForm, InterfaceBuySparepartForm, interfaceBuyPaidForm } from "../data/buy.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createBuy, createBuyPaid, editBuy, getBuy } from "../data/buy.api";
import SupplierSelect from "../../supplier/components/SupplierSelect";
import FormDate from "../../../shared/form/FormDate";
import FormCheck from "../../../shared/form/FormCheck";
import SparepartSelect from "../../sparepart/components/SparepartSelect";
import FormMoney from "../../../shared/form/FormMoney";
import FormNumber from "../../../shared/form/FormNumber";
import moneyParser from "../../../shared/moneyParser";
import { Add, Delete } from "@mui/icons-material";
import AccountSelect from "../../account/components/AccountSelect";
import { useSetting } from "../../../hooks/useSetting";
import moment from "moment";

export default function BuyForm(props: { mode: "ADD" | "EDIT", data?: InterfaceBuyForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceBuyForm>(props.data || InitialBuyForm)

    const [isCredit, setIsCredit] = useState<boolean>(props.data?.due_date ? true : false)
    const [tempSparepart, setTempSparepart] = useState<InterfaceBuySparepartForm>(InitialBuySparepartForm)
    const [buyPaidTemp, setBuyPaidTemp] = useState<interfaceBuyPaidForm>(InitialBuyPaidForm)

    const total = values.sparepart_list.reduce((prev, curr) => prev + (curr.price * curr.qty), 0)
    const totalPaid = values.paid_list.filter(v => v.account_id !== setting.getValue("ACCOUNT_DEBT")).reduce((prev, curr) => prev + curr.value, 0)

    useEffect(() => {
        if (props.mode === "ADD" && values.paid_list.length === 0) {
            const value: interfaceBuyPaidForm = {
                name: "Pembayaran",
                account_source_id: setting.getValue("ACCOUNT_BUY"),
                account_id: 0,
                value: 0
            }
            handleChangeValues("paid_list", [value])
        }
    }, [setting, props.mode, handleChangeValues, values])

    useEffect(() => {
        if (props.mode === "EDIT") {
            setBuyPaidTemp({
                ...InitialBuyPaidForm,
                name: "Pembayaran",
                value: total - totalPaid
            })
        }
    }, [props.mode, total, totalPaid])

    useEffect(() => {
        const total = values.sparepart_list.reduce((prev, curr) => prev + (curr.price * curr.qty), 0)
        if (values.paid_list.length > 0) { // pastikan paid_list tidak kosong
            if (isCredit) { // jika kredit
                if (values.paid_list[0].name !== "Uang Muka") {
                    handleChangeValues(
                        "paid_list",
                        [
                            ...values.paid_list.map((paid, i) => i === 0 ? { ...paid, name: "Uang Muka" } : paid),  // ubah nama uang muka
                            {                                                                                       // tambah akun hutang
                                ...InitialBuyPaidForm,
                                name: "Akun Hutang",
                                account_source_id: setting.getValue("ACCOUNT_DEBT"),
                                account_id: setting.getValue("ACCOUNT_BUY"),
                                value: 0
                            }
                        ]
                    )
                }
                else if (total !== values.paid_list[0].value + values.paid_list[1].value) { // jika total tidak sama dengan uang muka + hutang
                    handleChangeValues(
                        "paid_list",
                        values.paid_list.map((paid, i) => i === 1 ? { ...paid, value: total - values.paid_list[0].value } : paid), // ubah nilai hutang
                    )
                }
            }
            else { // jika tunai
                handleChangeValues("due_date", null)
                if (total !== values.paid_list[0]?.value) {
                    handleChangeValues(
                        "paid_list",
                        values.paid_list
                            .map((paid, i) => i === 0 ? { ...paid, name: "Pembayaran", account_id: setting.getValue("ACCOUNT_BUY"), value: total } : paid) // ubah nama pembayaran dan nilai
                            .filter((_, i) => i === 0) // hapus akun hutang jika ada
                    )
                }
                else {
                    handleChangeValues(
                        "paid_list",
                        values.paid_list
                            .map((paid, i) => i === 0 ? { ...paid, name: "Pembayaran", account_id: setting.getValue("ACCOUNT_BUY") } : paid) // ubah nama pembayaran saja
                            .filter((_, i) => i === 0) // hapus akun hutang jika ada
                    )
                }
            }
        }
    }, [values, handleChangeValues, isCredit, setting])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createBuy({
                    supplier_id: values.supplier_id,
                    description: values.description,
                    due_date: isCredit ? values.due_date : null,
                    sparepart_list: values.sparepart_list,
                    paid_list: values.paid_list
                })).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editBuy(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getBuy())
            props.onSubmit()
        } catch (error) { }
    }

    const handleSubmitPaid = async () => {
        try {
            await dispatch(createBuyPaid({
                buy_id: values.id,
                account_id: buyPaidTemp.account_id,
                value: buyPaidTemp.value
            })).unwrap()
            await dispatch(getBuy())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <SupplierSelect value={values.supplier_id} onChange={(value) => handleChangeValues("supplier_id", value)} required />
            <FormText label={"Deskripsi"} name="description" value={values.description} onChange={handleChange} />
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sparepart</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.sparepart_list.map((sparepart, index) => (
                            <TableRow key={index}>
                                <TableCell><SparepartSelect value={sparepart.sparepart_id} readOnly={props.mode === "EDIT"} onChange={() => { }} /></TableCell>
                                <TableCell>{moneyParser(sparepart.price)}</TableCell>
                                <TableCell>{sparepart.qty}</TableCell>
                                <TableCell>{moneyParser(sparepart.price * sparepart.qty)}</TableCell>
                                <TableCell>
                                    {props.mode === "ADD" && (
                                        <Tooltip title="Hapus">
                                            <IconButton
                                                onClick={() => handleChangeValues("sparepart_list", values.sparepart_list.filter((_, i) => i !== index))}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {props.mode === "ADD" && (
                            <TableRow>
                                <TableCell>
                                    <SparepartSelect value={tempSparepart.sparepart_id} onChange={(_, valueDetail) => setTempSparepart(last => ({ ...last, sparepart_id: valueDetail?.id || 0, price: valueDetail?.price || 0 }))} />
                                </TableCell>
                                <TableCell>
                                    <FormMoney label="Harga Satuan" value={tempSparepart.price} onChange={(e) => setTempSparepart(last => ({ ...last, price: parseInt(e.target.value) }))} />
                                </TableCell>
                                <TableCell>
                                    <FormNumber label="Qty" value={tempSparepart.qty} onChange={(e) => setTempSparepart(last => ({ ...last, qty: parseInt(e.target.value) }))} />
                                </TableCell>
                                <TableCell>
                                    <Typography>{moneyParser(tempSparepart.price * tempSparepart.qty)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Tambah">
                                        <IconButton
                                            onClick={() => {
                                                handleChangeValues("sparepart_list", [...values.sparepart_list, tempSparepart])
                                                setTempSparepart(InitialBuySparepartForm)
                                            }}
                                        >
                                            <Add />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            <FormCheck label={"Kredit"} name="is_credit" value={isCredit} onChange={(e) => setIsCredit(e.target.value as unknown as boolean)} disabled={props.mode === "EDIT"} />
            {isCredit && (
                <FormDate readOnly={props.mode === "EDIT"} label={"Tanggal Jatuh Tempo"} name="due_date" value={moment(values.due_date).format("YYYY-MM-DD")} onChange={handleChange} required={isCredit} />
            )}
            {props.mode === "ADD"
                ? (
                    <Grid container columnSpacing={1}>
                        <Grid item xs={6}>
                            <AccountSelect label="Akun Sumber" value={setting.getValue("ACCOUNT_BUY")} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormMoney value={values.sparepart_list.reduce((prev, curr) => prev + (curr.price * curr.qty), 0)} />
                        </Grid>
                        {values.paid_list.length >= 1 && (
                            <Fragment>
                                <Grid item xs={6}>
                                    <AccountSelect
                                        required
                                        disabledHead
                                        headId={setting.getValue("ACCOUNT_MAIN_FLOW")}
                                        label={values.paid_list[0].name}
                                        value={values.paid_list[0].account_source_id}
                                        onChange={(value) => handleChangeValues("paid_list", values.paid_list.map((paid, i) => i === 0 ? { ...paid, account_source_id: value } : paid))}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormMoney value={values.paid_list[0].value} onChange={(e) => handleChangeValues("paid_list", values.paid_list.map((paid, i) => i === 0 ? { ...paid, value: parseInt(e.target.value) } : paid))} disabled={!isCredit} />
                                </Grid>
                            </Fragment>
                        )}
                        {values.paid_list.length >= 2 && (
                            <Fragment>
                                <Grid item xs={6}>
                                    <AccountSelect required disabledHead label={values.paid_list[1].name} value={values.paid_list[1].account_source_id} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormMoney value={values.paid_list[1].value} onChange={(e) => handleChangeValues("paid_list", values.paid_list.map((paid, i) => i === 0 ? { ...paid, value: parseInt(e.target.value) } : paid))} disabled />
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                )
                : (
                    <Table>
                        <TableBody>
                            {values.paid_list.map((paid, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {paid.name}
                                    </TableCell>
                                    <TableCell>
                                        <AccountSelect readonly value={paid.account_source_id} />
                                    </TableCell>
                                    <TableCell>
                                        <FormMoney readOnly value={paid.value} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {totalPaid < total && (
                                <TableRow>
                                    <TableCell>{buyPaidTemp.name}</TableCell>
                                    <TableCell>
                                        <AccountSelect required disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label={"Akun"} value={buyPaidTemp.account_source_id} onChange={value => setBuyPaidTemp(last => ({ ...last, account_id: value || 0 }))} />
                                    </TableCell>
                                    <TableCell>
                                        <FormMoney value={buyPaidTemp.value} onChange={(e) => setBuyPaidTemp(last => ({ ...last, value: parseInt(e.target.value) }))} />
                                    </TableCell>
                                    <TableCell>
                                        <Button sx={{ marginTop: "4px" }} onClick={() => handleSubmitPaid()}>Simpan</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )
            }
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}