import { createSlice } from '@reduxjs/toolkit'
import { appendBookingItinerary, appendDeliveryItinerary, attachmentGet, attachmentUpload, closeItinerary, createItinerary, createItineraryCost, deleteItinerary, deleteItineraryCost, editItinerary, editItineraryCost, getItinerary, getItineraryByScheduleAndStartDate, getItineraryCalendar, getItineraryDetail, getItineraryOpen, getItineraryReport } from './itinerary.api'
import { InterfaceItineraryAttachmentData, InterfaceItineraryCalendar, InterfaceItineraryData, InterfaceItineraryDetailData, InterfaceItineraryInput, InterfaceItineraryReport } from './itinerary.interface'
import alertError from '../../../shared/alert/alertError'

export interface ItineraryState {
    loading: boolean,
    itineraryList: InterfaceItineraryData[],
    itineraryListTotal: number,
    itineraryCalendarList: InterfaceItineraryCalendar[],
    itineraryOpenList: InterfaceItineraryData[],
    itineraryOpenListTotal: number,
    itineraryDetail?: InterfaceItineraryDetailData,
    itineraryInput: InterfaceItineraryInput,
    attachmentList: InterfaceItineraryAttachmentData[]
    reportList: InterfaceItineraryReport[]
}

const initialState: ItineraryState = {
    loading: false,
    itineraryList: [],
    itineraryListTotal: 0,
    itineraryCalendarList: [],
    itineraryOpenList: [],
    itineraryOpenListTotal: 0,
    itineraryInput: {},
    attachmentList: [],
    reportList: []
}

export const itinerarySlice = createSlice({
    name: 'itinerary',
    initialState,
    reducers: {
        setItineraryInput: (state, action: { type: string, payload: InterfaceItineraryInput }) => {
            state.itineraryInput = action.payload
        },
        resetItineraryInput: (state) => {
            state.itineraryInput = {}
        }
    },
    extraReducers(builder) {

        builder.addCase(getItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getItinerary.fulfilled, (state, action) => {
            state.loading = false
            state.itineraryList = action.payload.data.data
            state.itineraryListTotal = action.payload.data.total_data
        })
        builder.addCase(getItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getItineraryCalendar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getItineraryCalendar.fulfilled, (state, action) => {
            state.loading = false
            state.itineraryCalendarList = action.payload.data.data
        })
        builder.addCase(getItineraryCalendar.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getItineraryByScheduleAndStartDate.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getItineraryByScheduleAndStartDate.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getItineraryByScheduleAndStartDate.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getItineraryOpen.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getItineraryOpen.fulfilled, (state, action) => {
            state.loading = false
            state.itineraryOpenList = action.payload.data.data
            state.itineraryOpenListTotal = action.payload.data.total_data
        })
        builder.addCase(getItineraryOpen.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getItineraryDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getItineraryDetail.fulfilled, (state, action) => {
            state.loading = false
            state.itineraryDetail = action.payload.data.data
        })
        builder.addCase(getItineraryDetail.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createItinerary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editItinerary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteItinerary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(editItineraryCost.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editItineraryCost.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editItineraryCost.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(createItineraryCost.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createItineraryCost.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createItineraryCost.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(deleteItineraryCost.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteItineraryCost.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteItineraryCost.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(closeItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(closeItinerary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(closeItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(appendBookingItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(appendBookingItinerary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(appendBookingItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(appendDeliveryItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(appendDeliveryItinerary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(appendDeliveryItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(attachmentUpload.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(attachmentUpload.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(attachmentUpload.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(attachmentGet.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(attachmentGet.fulfilled, (state, action) => {
            state.loading = false
            state.attachmentList = action.payload.data.data
        })
        builder.addCase(attachmentGet.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })


        builder.addCase(getItineraryReport.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getItineraryReport.fulfilled, (state, action) => {
            state.loading = false
            state.reportList = action.payload.data.data
        })
        builder.addCase(getItineraryReport.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

    },
})

export const { setItineraryInput, resetItineraryInput } = itinerarySlice.actions

export default itinerarySlice.reducer